<template>
  <div>
    <!-- <h3>Customer Details:</h3>
            <div class="row">
                <div class="col-md-5 text-left">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>User Name :</b>
                        </div>
                        <div class="col-md-8">
                            {{ object.billing_name }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Mobile :</b>
                        </div>
                        <div class="col-md-8">
                            {{ object.billing_mobile }}
                        </div>
                    </div>
                </div>
                <div class="col-md-7 text-left">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Address :</b>
                        </div>
    <div class="col-md-8">-->
    <!-- {{ object.address_id.address_line_1 }}, {{ object.address_id.address_line_2 }} -->
    <!-- </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Email :</b>
                        </div>
                        <div class="col-md-8">
                            {{ object.user_email }}
                        </div>
                    </div>
                </div>
            </div>
    <hr />-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 customer-details-block">
      <h4>Customer Details:</h4>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="this.order.order_type == 2"
      >
        Customer :
        {{ order.shipping_name != undefined ? order.shipping_name : "" }}
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" v-else>
        Customer :
        {{ order.shipping_name != undefined ? order.shipping_name : "" }}
        <!-- {{relUserData.last_name != undefined ? relUserData.last_name : ""}} -->
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="order.order_type != 2"
      >
        <div
          style="display: inline-block; width: 100%;"
          v-show="relDelAddressData"
        >
          Address :
          {{ relDelAddressData.address_line_1 }},
          {{ relDelAddressData.address_line_2 }},
          {{ relDelAddressData.city ? relDelAddressData.city.city_name : "" }}
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" v-else>
        <div style="display: inline-block;width: 100%;">
          Address :
          {{ addressOrderType2 != undefined ? " " + addressOrderType2 : "" }}
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 1"
      >
        Payment Method : Supiri Pola Credit
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 2"
      >
        Payment Method : Cash On Delivery
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 3"
      >
        Payment Method : Bank Transfer
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 4"
      >
        Payment Method : Card Payment
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc">
        Mobile : {{ order.shipping_mobile }}
      </div>
      <div
        v-if="relUserData.secondary_mobile"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc"
      >
        Home Number : {{ relUserData.secondary_mobile }}
      </div>
      <div
        v-if="!relUserData.secondary_mobile"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc"
      >
        Home Number : Not Available
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc">
        Email : {{ relUserData.email }}
      </div>
    </div>

    <div class="row">
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style="padding-top: 12px;"
      >
        <h2 class="title" style="margin-top: 20px;">
          Order Id: {{ relOrderId }}
        </h2>
        <table id="example" class="table table-striped table-border-out">
          <thead>
            <tr>
              <th class="head-table">Image</th>
              <th class="head-table">Order ID</th>
              <th class="head-table">Product Name</th>
              <th class="head-table">Date</th>
              <th class="head-table">Quantity</th>
              <th class="head-table">Unit Price</th>
              <th class="head-table">Total Charge</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item1, index1) in object.order_lines" :key="index1">
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                <img
                  class="img-thumbnail"
                  width="70px"
                  :src="item1.simple_product_id.images[0].image"
                />
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.order_env_number }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.product_name }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.date }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.qty }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                <b>Rs.</b>
                {{ item1.unit_price }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                <b>Rs.</b>
                {{ item1.unit_price * item1.qty.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div id="table-content" class style>
          <h4 class="title">Delivery Partner Details</h4>
          <table id="example" class="table table-striped table-border-out">
            <thead>
              <tr>
                <th scope="col" class="head-table" style="text-align: center;">
                  Company Name
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Charge
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Email Address
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Mobile Number
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in delivery_partners" :key="index">
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.company_name }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  Rs. {{ item.charge }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.email }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.mobile }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div id="table-content" class>
          <h4 class="title" style="    margin-top: 20px; margin-bottom: 20px;">
            Outlet Details
          </h4>
          <table id="example" class="table table-striped table-border-out">
            <thead>
              <tr>
                <th scope="col" class="head-table" style="text-align: center;">
                  Outlet Name
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Email Address
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Mobile Number
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in outletDataView" :key="index">
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.simple_product_id.product_id.store.store_name }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.simple_product_id.product_id.store.email }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.simple_product_id.product_id.store.phone }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row" style="margin-left: 0px; margin-right:0px;">
      <div class="bottom-form">
        <!-- <div class="row" style="margin-left: 0px; margin-right:0px;">		
            <div class="col-sm-12">
              <div class="col-sm-4">
              </div>
              <div class="col-sm-2">
                <label>Items</label>
                <h4>{{ orderLines.length!= undefined ? orderLines.length : "" }}</h4>
              </div>

              <div class="col-sm-2">
                <label>Sub Total</label>
                <h4>Rs. {{ subtotal }}</h4>
              </div>

              <div class="col-sm-2">
                <label>Shipping and Handling fee</label>
                 <h4>Rs. {{ order.total_delivery_charge!= undefined ? order.total_delivery_charge : ""}}</h4>
              </div>

              <div class="col-sm-2">
                <label>Total</label>
                 <h4>Rs. {{ order.total_charge != undefined ? order.total_charge : " "  }}</h4>
              </div>
            </div>
        </div>-->

        <!-- New -->
        <div class="row" style="margin-left: 0px;margin-right: 0px;">
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            style="padding-left: 0px;padding-right: 0px;"
          >
            <table class="table">
              <thead>
                <tr>
                  <th>Items In Cart</th>
                  <th>Sub Total</th>
                  <th>Shipping and Handling Fee</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: center;">
                    <h4>
                      {{
                        orderLines.length != undefined ? orderLines.length : ""
                      }}
                    </h4>
                  </td>
                  <td style="text-align: center;">
                    <h4>Rs. {{ subtotal }}</h4>
                  </td>
                  <td style="text-align: center;">
                    <h4>
                      Rs.
                      {{
                        order.total_delivery_charge != undefined
                          ? order.total_delivery_charge
                          : ""
                      }}
                    </h4>
                  </td>
                  <td style="text-align: center;">
                    <h4>
                      Rs.
                      {{
                        order.total_charge != undefined
                          ? order.total_charge
                          : " "
                      }}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <hr v-if="object.payment_type === 3" />
    <h3 v-if="object.payment_type === 3">Payment Details</h3>
    <div class="row" style="display: flex; justify-content: center;">
      <div
        class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-left"
        :style="
          relUserData.fullObj.payment_type == 4
            ? 'border-right:0px solid white;'
            : 'border-right: 1px solid #ccc; padding-right: 20px; '
        "
      >
        <div class="row" v-if="object.payment_type === 3">
          <div class="col-md-12">
            <B style="    font-size: 14px;">Customer attached bank slip:</B>
          </div>
          <div class="col-md-12">
            <a :href="object.payment_image" target="_blank">
              <img
                slot="image"
                class="card-img-top"
                :src="object.payment_image"
                alt="Card image cap"
                style="cursor: pointer;width: 100%; height: 300px;"
              />
            </a>
          </div>
        </div>
        <br />
      </div>
      <div
        class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
        style="padding-left: 20px;"
      >
        <div v-if="(userRole === 1 && relUserData.fullObj.payment_type === 3) || (userRole === 5 && relUserData.fullObj.payment_type === 3)">
          <div class="row">
            <div class="col-md-12">
              <B style="font-size: 14px;">Attach another slip:</B>
            </div>
          </div>
          <div
            v-if="!bankSlipImage"
            style="text-align: left;margin-top:10px; font-weight: 600; font-size:14px;"
          >
            <button
              type="button"
              class="btn btn-secondary"
              style="color: black; font-weight: 600;width:150px;"
              @click="pickCoverImage()"
            >
              Upload
            </button>
            <!-- <input type="file" class="inputfile" id="embedpollfileinput" @change="getFile"> -->
            <input
              type="file"
              style="display: none;"
              ref="coverImage"
              accept="image/x-png, image/jpeg"
              @change="getImageRes()"
            />
            <p>
              <span
                style="display: inline-block;font-size: 12px;margin-right: 12px;font-weight: normal;"
              >
                Allowed file types:
                <span style="display: inline-block;margin-left: 7px;"
                  >.jpg, .jpeg, .png,</span
                >
              </span>
              <span
                style="display: inline-block;font-size: 12px;font-weight: normal;"
              >
                Maximum upload file size:
                <span style="display: inline-block;margin-left: 7px;">2MB</span>
              </span>
            </p>
          </div>
          <div class="row" v-if="showSpinUploadImg">
            <div class="sk-chase">
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
            </div>
          </div>
          <div class="row" v-if="bankSlipImage">
            <div class="col-md-12">
              <img
                style="margin-top:10px; width: auto; height:300px;"
                slot="image"
                :src="bankSlipImage"
              />
            </div>
            <div class="col-md-12" style="margin-top:10px; margin-bottom:10px;">
              <button
                type="button"
                class="btn btn-secondary"
                style="color: black; font-weight: 600; width:80px;"
                @click="removeImg()"
              >
                Remove
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                class="form-group"
                style="display: flex;flex-direction: column;"
              >
                <label class="control-label" for="input-email">
                  <B>Comments</B>
                </label>
                <textarea
                  v-model="reason"
                  placeholder="Enter reason"
                  id="reason"
                  rows="4"
                  cols="50"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-7 text-left">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Total Charge :</b>
                        </div>
                        <div class="col-md-8">
                            <b>Rs. </b>{{ object.total_charge }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-6">
      <br />-->
      <!-- <button type="button" class="btn btn-sm btn-primary" style="background-color: #ffd338; border: none;" @click="handleStatusChange(object._id, approveStatus)">Approv</button>&nbsp; -->
      <!-- </div>
                    </div>
      </div>-->
    </div>
    <div class="row" style="display: margin-top:20px;"  v-if="userRole === 1 || userRole === 5">
      <div class="col-md-6">
        <b>Approval Status</b>
        <br />
        <select
          v-model="approveStatus"
          style="display: inline-block; width: 100%; margin-right: 18px;"
          class="form-control select-arrow"
        >
          <option
            v-for="item2 in statusArray"
            :key="item2.key"
            :value="item2.value"
            >{{ item2.text }}</option
          >
        </select>
        <br />
      </div>
    </div>
    <div class="row" v-if="userRole === 1 || userRole === 5">
      <div class="col-md-12 text-right">
        <!-- <button type="button" :disabled="!validateField" class="btn btn-sm merchprof-sbtn"  @click="handleStatusChange(object._id, approveStatus)" >Submit</button> &nbsp;&nbsp;&nbsp; -->
        <button
          type="button"
          class="btn btn-sm merchprof-sbtn"
          @click="handleStatusChange(object._id, approveStatus)"
          :disabled="!isFormValid"
          v-if="object.payment_type === 3"
        >
          Submit
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-sm merchprof-sbtn"
          @click="handleStatusChange(object._id, approveStatus)"
          v-if="object.payment_type != 3"
        >
          Submit
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-sm merchprof-sbtn"
          @click="handleBack()"
        >
          Back
        </button>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import mixinStore from "@/mixins/merchant/APIStore";
import mixinOrder from "@/mixins/merchant/APIOrder";
export default {
  mixins: [mixinStore, mixinOrder],
  data: function() {
    return {
      breadcrumb: [
        {
          text: "Pending Approval",
          href: "/merchant-pending-order",
          active: false,
        },
        {
          text: "Approval Configure",
          active: false,
        },
      ],
      reason: "",
      statusArray: [
        {
          key: 1,
          text: "Approve",
          value: true,
        },
        {
          key: 2,
          text: "Reject",
          value: false,
        },
      ],
      showSpinUploadImg: false,
      approveStatus: true,
      object: {},
      showSubComp: 0,
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      searchKeyStatus: "all",
      tableItems: [],
      blockLoading: true,
      paymentFilteroptions: [
        { text: "All", value: "all" },
        { text: "Bank Transfer", value: "banktransfer" },
        { text: "Credit Card", value: "creditcard" },
      ],
      relUserData: {},
      relDelAddressData: "",
      addressOrderType2: "",
      relOrderId: "",
      order: {},
      outletDataView: [],
      orderLines: [],
      subtotal: 0,
      delivery_partners: [],
      image: null,
      userRole: ""
    };
  },
  computed: {
    bankSlipImage: function() {
      if (this.image) {
        return this.image;
      } else {
        return "";
      }
    },
    validateField: function() {
      let status = false;
      if (this.relUserData.fullObj.payment_type === 4) {
        status = false;
        return status;
      }
      if (this.object.rejectedOrd) {
        status = true;
        return status;
      }
      // if (!this.approveStatus && this.image==null && this.reason=="" || this.approveStatus && this.reason!="") {
      //     status = true;
      //     return status;
      // }
      if (!this.approveStatus && this.image == null && this.reason != "") {
        status = true;
        return status;
      }
      if (this.image != null && this.reason == "") {
        status = true;
      } else {
        status = false;
      }
      if (this.object.rejectedOrd) {
        status = true;
        return status;
      }
      return status;
    },
    isFormValid() {
      return this.reason != "";
    },
  },
  watch: {
    searchKey: function(val) {
      let sType = "orderid";
      this.searchCustomers(val, sType);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    },
  },
  created() {
    this.handleBreadcrumb();
    this.handleGetOrderList();
    this.getSingleOrderDetails();
    let mdata = JSON.parse(localStorage.merchant_data);
    this.userRole = mdata.role;
  },
  methods: {
    removeImg: function() {
      this.image = null;
    },
    pickCoverImage: function() {
      this.$refs.coverImage.click();
    },
    getImageRes: async function() {
      try {
        this.showSpinUploadImg = true;
        let fileObj = this.$refs.coverImage.files[0];
        // Validate file size
        if (fileObj.size >= 1024 * 5120) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please upload below 2MB file",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          const fd = new FormData();
          fd.append("image", fileObj);
          let response = await this.putBankSlipImage(fd);
          if (response) {
            this.showSpinUploadImg = false;
            this.image = response.image;
          }
        }
      } catch (error) {
        this.showSpinUploadImg = false;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Submit failed",
          showConfirmButton: false,
          timer: 1500,
        });
        throw error;
      }
    },
    getSingleOrderDetails: async function() {
      var orderId = this.$route.params.id
        ? this.$route.params.id
        : localStorage.pending_order_group_id;
      try {
        let response = await this.getOrderListNotApprById(orderId);
        this.object = response;

        if (
          this.object.admin_approve == false &&
          this.object.order_status == 0
        ) {
          this.object.rejectedOrd = true;
        } else {
          this.object.rejectedOrd = false;
        }
        // if(){
        //     this.image= this.object.
        // }
        var orderLinesArray = [];
        var orderLinesArray = response.order_lines;
        this.orderLines = response.order_lines;
        this.order = response;

        if (this.order.order_type == 2) {
          this.relUserData.fullObj = this.order;
          this.relUserData.name = this.order.shipping_name;
          this.relUserData.mobile = this.order.shipping_mobile;
          this.relUserData.email = this.order.user_email;
          this.relOrderId = this.order.order_env_number;
          this.addressOrderType2 = this.order.robo_shipping_address;
        } else {
          this.relOrderId = response.order_env_number;
          this.relUserData = response.user_id;
          this.relUserData.fullObj = response;
          this.relDelAddressData = response.address_id;
        }

        // Check duplicate outlet data & fetch
        var displayOutlets = [];
        var outlets = [];
        for (var i = 0, len = orderLinesArray.length; i < len; i++) {
          if (
            outlets.indexOf(
              orderLinesArray[i].simple_product_id.product_id.store._id
            ) > -1
          ) {

          } else {
            outlets.push(
              orderLinesArray[i].simple_product_id.product_id.store._id
            );
            displayOutlets.push(orderLinesArray[i]);
          }
        }
        this.outletDataView = displayOutlets;

        this.populateDeliveryPartners();
        this.calculateSubtotal();
      } catch (error) {
        throw error;
      }
    },
    populateDeliveryPartners: function() {
      this.delivery_partners = [];
      let dp_arraytxt = [];
      let dp_charges = [];
      for (let index = 0; index < this.orderLines.length; index++) {
        const element = this.orderLines[index];
        let delivery_partner = {
          company_name: element.delivery_partner.company_name,
          mobile: element.delivery_partner.mobile,
          email: element.delivery_partner.email,
          _id: element.delivery_partner._id,
        };
        dp_arraytxt.push(JSON.stringify(delivery_partner));
        dp_charges.push({
          _id: element.delivery_partner._id,
          charge: element.delivery_charge,
        });
      }

      var uniquedp = dp_arraytxt.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });

      for (let index = 0; index < uniquedp.length; index++) {
        const element = uniquedp[index];
        let del_charge = 0;
        let obj = JSON.parse(element);
        dp_charges.forEach((element) => {
          if (element._id == obj._id) {
            del_charge = element.charge + del_charge;
          }
        });
        obj.charge = del_charge;
        this.delivery_partners.push(obj);
      }
    },
    calculateSubtotal: function() {
      this.subtotal = 0;
      this.orderLines.forEach((item) => {
        this.subtotal = item.unit_price * item.qty + this.subtotal;
      });
    },
    filterOrderPayType: function() {
      let sType = "pay";
      this.searchCustomers(this.searchKeyStatus, sType);
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    handleGetOrderList: async function() {
      try {
        this.tableItems = [];
        this.storeListData = [];
        this.blockLoading = true;

        this.storeListData = await this.getOrderList();

        // Check order list & filter out rejected order
        var pendingToApproveRejOrders = [];
        pendingToApproveRejOrders = this.storeListData.filter((item) => {
          return item.admin_approve == false && item.order_status != 0;
        });

        // Set item count for orders
        pendingToApproveRejOrders.forEach((obj) => {
          obj.itemCount = obj.order_lines.length;
        });


        this.storeListData = pendingToApproveRejOrders;

        this.searchCustomers("");
        this.blockLoading = false;
      } catch (error) {
        this.$toast.error("Could not get details!", "Error", {
          position: "topRight",
        });
      }
    },
    searchCustomers: function(keyword, type = "") {
      if (type == "pay") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                if (keyword == "banktransfer") {
                  return obj.payment_type == 3;
                }

                if (keyword == "creditcard") {
                  return obj.payment_type == 4;
                }

                if (keyword == "all") {
                  return obj;
                }
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      } else if ("orderid") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                return (
                  obj.order_env_number && obj.order_env_number.includes(keyword)
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      } else {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                return (
                  obj.order_env_number && obj.order_env_number.includes(keyword)
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      }
    },
    handleBack() {
      this.object = {};
      // this.showSubComp = 0;
      this.$router.push({ name: "Merchant Approve Reject Order" });
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endsize - pagesize;
      }
      this.tableItems = this.storeListData.slice(startsize, endsize);
    },
    handleStatusChange: async function(id, condition) {
      let aproveObj = {};
      aproveObj._id = id;
      aproveObj.status = condition;
      if (this.image != null) {
        if (this.reason == "") {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please fill commemt field",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
        aproveObj.image = this.image;
        aproveObj.comment = this.reason;
      }
      if (condition === true) {
        try {
          await this.changeStatusOrder(aproveObj);
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Successfully Approved!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.reason = "";
          this.$router.push({ path: "/merchant-pending-order" });
        } catch (error) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Couldn't Approved!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.handleReject(aproveObj);
      }
      await this.handleGetOrderList();
    },
    handleReject: async function(obj) {
      try {
        let self = this;
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, reject it!",
        }).then((result) => {
          if (result.value) {
            self.changeStatusOrder(obj);
            this.$swal("Reject!", "Order has been rejected.", "success");
            this.$router.push({ path: "/merchant-pending-order" });
          }
        });
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Couldn't Reject!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    rejectOrderDetail: async function(value) {
      try {
        await this.changeStatusOrder(value);
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Couldn't Reject!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    handleView(obj) {
      this.object = {};
      this.showSubComp = 1;
      this.object = obj;

      localStorage.setItem("pending_order_group_id", obj._id);
      this.$router.push({
        name: "Pending Order Lines",
        params: { id: obj._id },
      });
    },
  },
};
</script>
<style>
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 265px;
}
.head-table {
  background: white !important;
  text-align: left;
}
.table-border-out {
  border: 1px solid #ddd;
}
.merchprof-sbtn {
  background: #ffd338;
  color: #000000;
  border: none;
  font-size: 14px;
}
.merchprof-sbtn:hover {
  color: #ffffff;
}
.customer-details-block {
  padding-top: 10px;
  padding-bottom: 10px;
}
.customer-details-block .innercus {
  background: #eeeeee;
}
.btn-upload {
  color: black;
  font-weight: 600;
  background-color: white;
  border: 1px solid;
  width: 150px;
}
.btn-upload:hover {
  color: black;
  font-weight: 400;
  background-color: white;
}
.btn-remove {
  color: black;
  font-weight: 600;
  background-color: white;
  border: 1px solid;
  width: 80px;
}
.btn-remove:hover {
  color: black;
  font-weight: 400;
  background-color: white;
}
.vl {
  border-left: 1px solid black;
  height: 500px;
}
</style>
